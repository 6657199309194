import PropTypes from "prop-types";
import React, { useState } from "react";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";

// project imports
import LogoSection from "../LogoSection";
// import SearchSection from './SearchSection';
// import LocalizationSection from './LocalizationSection';
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
// import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from "@tabler/icons";
import { Link } from "react-router-dom";
import { MENU_OPEN } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { drawerCoins, loading_dots } from "../../../assets/images";
import { Refresh } from "@material-ui/icons";
import { useEffect } from "react";
import axios from "axios";
import { logFirebaseEvent } from "../../../LogFirebaseEvent";
import dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

// style constant
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: "all .2s ease-in-out",
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.secondary.light,
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.secondary.dark,
    "&:hover": {
      background:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.main
          : theme.palette.secondary.dark,
      color:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.light
          : theme.palette.secondary.light,
    },
  },
  boxContainer: {
    width: "228px",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = ({ handleLeftDrawerToggle }) => {
  const classes = useStyles();
  const [loadingCoins, setLoadingCoins] = useState(false);
  const [usercoins, setUsercoins] = useState(0);
  const userInfo = useSelector((state) => state?.userInfo?.userData);
  const salonDetails = userInfo?.salonDetails?.[0];

  // const handleRefreshCoins = () => {
  //     setLoadingCoins(true)
  //     setTimeout(() => {
  //         axios.get(process.env.REACT_APP_PATH + '/sellerCrm/role2/getOwnerCoins')
  //             .then((res) => {
  //                 // console.log('res', res.data)
  //                 if (res.data.success) {
  //                     setLoadingCoins(false)
  //                     setUsercoins(res?.data?.data || 0)
  //                 } else {
  //                     alert("Something went wrong ! , Please Refresh")
  //                 }
  //             })
  //             .catch((err) => {
  //                 setLoadingCoins(false)
  //                 // alert(err)
  //             })
  //     }, 1000);
  // }
  // useEffect(() => {
  //     handleRefreshCoins()
  // }, [])
  return (
    <React.Fragment>
      {/* logo & toggler button */}
      <div className={classes.boxContainer}>
        <Box
          component="span"
          sx={{
            display: { xs: "none", md: "block" },
            flexGrow: 1,
          }}
        >
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </div>

      {/* header search */}
      {/* <SearchSection theme="light" /> */}
      <div className={classes.grow} />

      <div className={classes.grow} />
      <div
        style={{
          //   boxShadow: "0px 2px 6px lightgray",
          //   borderRadius: 8,
          padding: "5px 10px",
          marginRight: 10,
        }}
      >
        <Grid container flexDirection={"column"} alignItems={"flex-end"}>
          {/* <img src={drawerCoins} width={30} />
          <Typography variant="h4">
            {loadingCoins ? <img src={loading_dots} width={30} /> : usercoins}
            &nbsp; Coins
          </Typography>
          {loadingCoins ? (
            <CircularProgress size={18} />
          ) : (
            <Refresh
              color="primary"
              sx={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          )} */}
          {/* <div> */}
          {/* <Typography color={"red"}>
            You free trial expires&nbsp;
            {dayjs(salonDetails?.createdAt).add(1, 'month').fromNow().toString()}
          </Typography>
          <Button size="small" sx={{ padding: 0, textDecoration: "underline" }}>
            Upgrade Now
          </Button> */}
          {/* </div> */}
        </Grid>
      </div>

      {/* live customization & localization */}
      {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}

      {/* notification & profile */}
      {/* <NotificationSection /> */}
      <ProfileSection />

      {/* mobile header */}
      {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box> */}
    </React.Fragment>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
