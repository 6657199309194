import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { TablePagination } from "@mui/material";
import SubCard from "../../ui-component/cards/SubCard";

function LorealCityWiseData() {
  const [orderList, setOrderList] = useState([
    {
      city: "Agra",
      state: "Uttar Pradesh",
      Sum_of_price: "33,40,728",
      Count_Of_Buyer: 301
    },
    {
      city: "Jaipur",
      state: "Rajasthan",
      Sum_of_price: "25,68,798",
      Count_Of_Buyer: 187
    },
    {
      city: "Surat",
      state: "Gujarat",
      Sum_of_price: "28,10,750",
      Count_Of_Buyer: 195
    },
    {
      city: "Lucknow",
      state: "Uttar Pradesh",
      Sum_of_price: "31,39,483",
      Count_Of_Buyer: 281
    },
    {
      city: "Patna",
      state: "Bihar",
      Sum_of_price: "31,81,964",
      Count_Of_Buyer: 294
    },
    {
      city: "Ahmedabad",
      state: "Gujarat",
      Sum_of_price: "10,23,760",
      Count_Of_Buyer: 139
    },
    {
      city: "Kota",
      state: "Rajasthan",
      Sum_of_price: "24,32,453",
      Count_Of_Buyer: 216
    },
    {
      city: "Bikaner",
      state: "Rajasthan",
      Sum_of_price: "19,40,958",
      Count_Of_Buyer: 203
    }
  ]);
  

  return (
    <SubCard title={"Cluster wise report"}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell >cluster</TableCell>
              <TableCell >State</TableCell>
              <TableCell >Total payable amount</TableCell>
              <TableCell >Count of Buyers</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderList &&
              orderList.map((order) => (
                <TableRow
                  key={order.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell >{order.city}</TableCell>
                  <TableCell  >{order.state}</TableCell>
                  <TableCell >{order?.Sum_of_price}</TableCell>
                  <TableCell >{order?.Count_Of_Buyer}</TableCell>
                </TableRow>
                 
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SubCard>
  );
}

export default LorealCityWiseData;
