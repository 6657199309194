// const role = localStorage.getItem('setRole')
const config = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: "/",
  defaultPath1: "/dashboard",
  defaultPath: "/my-profile",
  // defaultPath: "/home/appointments",
  fontFamily: `Lato-Regular`,
  borderRadius: 8,
  outlinedFilled: true,
  theme: "light",
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  i18n: "en",
  rtlLayout: false,
  BASE_URL: "https//",
  jwt: {
    secret: "SECRET-KEY",
    timeout: "1 days",
  },
  firebase: {
    apiKey: "AIzaSyD7AujvvcMaT6tpbDZqGIUkcr_Jft8O3ZE",
    authDomain: "buyumeapp.firebaseapp.com",
    databaseURL: "https://buyumeapp-default-rtdb.firebaseio.com",
    projectId: "buyumeapp",
    storageBucket: "buyumeapp.appspot.com",
    messagingSenderId: "814685535851",
    appId: "1:814685535851:web:1686ffa73948e91d713b2e",
    measurementId: "G-MD6P8BYTSZ"
  },
  // firebase: {
  //   apiKey: "AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM",
  //   authDomain: "berry-material-react.firebaseapp.com",
  //   projectId: "berry-material-react",
  //   storageBucket: "berry-material-react.appspot.com",
  //   messagingSenderId: "901111229354",
  //   appId: "1:901111229354:web:a5ae5aa95486297d69d9d3",
  //   measurementId: "G-MGJHSL8XW3",
  // },
  auth0: {
    client_id: "HvYn25WaEHb7v5PBsdfgT7cTYe98XATStX3r",
    domain: "demo-localhost.us.auth0.com",
  },
};

export default config;
