import * as React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
// import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
// import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
// import SaveIcon from '@mui/icons-material/Save';
// import PrintIcon from '@mui/icons-material/Print';
// import ShareIcon from '@mui/icons-material/Share';
import { Typography } from '@material-ui/core';
// import { ContactSupport } from '@material-ui/icons';

import { Call, ContactSupport, WhatsApp } from '@mui/icons-material';
import { logFirebaseEvent } from '../../../LogFirebaseEvent';


export default function SpeedDialTooltipOpen() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let actions = [
        {
            id: 1,
            name: 'Call',
            icon: <Call sx={{ color: 'green' }} />
        },
        {
            id: 2,
            name: 'Whatsapp',
            icon: <WhatsApp sx={{ color: 'green' }} />
        }
    ]
    const handleLinkOpen = (actionID) => {

        setOpen(false)
        if (actionID == 2) {
            logFirebaseEvent('contactSupport on whatsapp')
            // window.open('http://wa.me/+919873041193')
            window.open('http://wa.me/+919873041193?text=hello!%20i%20need%20help%20about%20Buyume%20Salon%20Management%20Software')
        } else {
            logFirebaseEvent('contactSupport on call')
            window.open('tel:+919873041193')

        }
    }
    return (
        <Box sx={{ height: '100%', transform: 'translateZ(0px)', flexGrow: 1 }}>
            <Backdrop open={open} />
            <div
                style={{
                    display: open ? 'flex' : 'none',
                    position: 'absolute', bottom: -5, right: -10,
                    height: '100vh', width: '100vw',
                    backgroundColor: '#0a0a0a90'
                }}
            >
                <Backdrop open={true} />

            </div>
            {/* <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{
                    position: 'absolute',
                    bottom: 16, right: 16,
                    zIndex: 1251,
                }}
                icon={<ContactSupport />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                FabProps={
                    {
                        color: 'secondary'
                    }
                }

            // openIcon={<Close />}


            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={<Typography color={'#002a5c'} >{action.name}</Typography>}
                        tooltipOpen
                        onClick={() => handleLinkOpen(action.id)}
                        color='#002a5c'

                    />
                ))}
            </SpeedDial> */}
        </Box>
    );
}