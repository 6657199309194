
import { Appointments } from './Appointments/index'
import { employee } from './EmployeeHome';
import { customer } from './CustomerHome';
import { services } from './Services';
import { employeeAccess } from './EmployeeAccess';
import { ONDC } from './ONDC';
import { LorealBrand } from './LorealBrand';

//-----------------------|| MENU ITEMS ||-----------------------//
let role = localStorage.getItem('setRole')
let phoneNumber  = localStorage.getItem('phoneNumber')

let Role1 = [Appointments, employee, customer, services,LorealBrand]
let Role2 = [Appointments, employee, customer, services,LorealBrand]
let Role3 = [Appointments, employeeAccess]
let Role4 = [Appointments, employeeAccess]
let RoleLoreal = [LorealBrand]
if(phoneNumber=='8826345311'){
    Role1.push(ONDC)
    Role2.push(ONDC)
}

if(phoneNumber=='8826345311'){

}
// let RoleBrand = [Reports]

let sidebarItems = {
    1: Role1,
    2: Role2,
    3: Role3,
    4: Role4,
    5:RoleLoreal
}
const menuItems = {
    items: role ? sidebarItems[role] : []
};

export default menuItems;







// items: [
//     // dashboard,
//     // widget,
//     Appointments,
//     employee,
//     customer,
//     services
//     // application,
//     // forms,
//     // elements,
//     // pages,
//     // utilities,
//     // support,
//     // other
// ]
// };